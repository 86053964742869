import React from 'react';
import { Typography } from '@mui/material';

import { styled } from '@mui/material/styles';

const StyledTypography = styled(Typography)(({ theme }) => ({
  '& .title': {
    fontWeight: 500,
    color: theme.palette.secondary.dark,
    marginBottom: theme.spacing(1),
    display: 'block',
    lineHeight: theme.typography.pxToRem(26),
    [theme.breakpoints.up('lg')]: {
      lineHeight: theme.typography.pxToRem(40),
    },
  },
  '& .desc': {
    color: theme.palette.secondary.dark,
    display: 'block',
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
}));

const SectionTitleWithDesc = (props) => {
  const {
    title,
    description,
    component = 'h1',
    className,
    mainTitleComponent = 'div',
    descComponent = 'p',
    titleVariant = 'h3',
  } = props;
  return (
    <>
      <StyledTypography className={className} component={mainTitleComponent}>
        {title && (
          <Typography
            variant={titleVariant}
            component={component}
            className="title">
            {title}
          </Typography>
        )}
        {description && (
          <Typography
            variant="body2"
            className="desc"
            component={descComponent}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        )}
      </StyledTypography>
    </>
  );
};

export { SectionTitleWithDesc };
