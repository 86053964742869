import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  '&.containerRoot': {
    maxWidth: 'unset',
    width: '100%',
  },
  '& .chirldrenWrapper': {
    width: '100%',
    position: 'relative',
  },
  '& .buttonGrid': {
    display: 'flex',
    justifyContent: 'end',
    fontSize: theme.typography.pxToRem(22),
    width: '100%',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('md')]: {
      order: 1,
      flexDirection: 'column',
    },
    '& .link': {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        width: '100%',
        '&:not(:first-child)': {
          marginTop: theme.spacing(1.5),
        },
      },
    },
    '& .button': {
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  },
  // buttons on lg screen when more than 1 button
  '& .buttonGrid.changeOrderOnMd': {
    [theme.breakpoints.down('lg')]: {
      order: 1,
      flexBasis: '100%',
      maxWidth: '100%',
      marginTop: theme.spacing(3),
    },
  },
}));

export default StyledBox;
