import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .SDAArrow': {
    position: 'absolute',
    top: theme.spacing(0.5),
    color: theme.palette.grey[300],
    backgroundColor: 'transparent',
    border: '0px',
    padding: 0,
    minWidth: 'unset',
    '&.SDALeftArrow': {
      left: theme.spacing(-5),
    },
    '&.SDARightArrow': {
      right: theme.spacing(-4),
    },
    '& .MuiSvgIcon-root': {
      fontSize: theme.spacing(4),
    },
  },
}));

export default StyledBox;
