import { styled } from '@mui/material/styles';
import Carousel from 'react-multi-carousel';
import { SliderSectionHeader } from 'views/common/components/UI/SliderSectionHeader/SliderSectionHeader';
import 'react-multi-carousel/lib/styles.css';

const StyledSliderSectionHeader = styled(SliderSectionHeader)(({ theme }) => ({
  '& .SDContiner': {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));

export const StyledCarousel = styled(Carousel)(({ theme, ssr }) => ({
  '&.react-multi-carousel-list': {
    position: 'static',
  },
  '& .react-multi-carousel-item': {
    '& .CarouselItem': {
      margin: theme.spacing(0, 0.625, 0.1, 0.625),

      '& .CarouselItem': {
        height: '100%',
        '& > .MuiGrid-container': {
          height: '100%',
        },
        '& > .MuiBox-root ': {
          height: '100%',
        },
      },
    },
    '&:first-child': {
      '& .CarouselItem': {
        margin: theme.spacing(0, 1.25, 0.1, 0),
      },
    },
    '&:last-child': {
      '& .CarouselItem': {
        margin: theme.spacing(0, 0.375, 0.1, 1.25),
      },
    },
    '&:only-child': {
      '& .CarouselItem': {
        margin: theme.spacing(0, 0.375, 0, 0),
      },
    },
  },
  '& .react-multiple-carousel__arrow': {
    position: 'absolute',
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: '50%',
    backgroundColor: 'rgba(48, 48, 48, 0.8)',
    transition: ssr && 'none',
    '&.react-multiple-carousel__arrow--right': {
      right: theme.spacing(-1.5),
    },
    '&.react-multiple-carousel__arrow--left': {
      left: theme.spacing(-1.5),
    },
    '&:before': {
      fontWeight: 'bold',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));

export default StyledSliderSectionHeader;
