import React from 'react';
import { Box, Button } from '@mui/material';
import { SectionTitleWithDesc } from 'views/common/components';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import StyledBox from 'views/common/components/UI/SliderSectionHeader/SliderSectionHeader.styles';

const SliderSectionHeader = (props) => {
  const {
    children,
    title,
    description,
    buttons,
    className,
    component,
    descComponent,
  } = props;

  return (
    <StyledBox className={`containerRoot ${className}`}>
      <SectionTitleWithDesc
        title={title}
        description={description}
        component={component}
        descComponent={descComponent}
      />

      <Box className="chirldrenWrapper" mt={3}>
        {children}
      </Box>
      {buttons && (
        <Box
          className={`buttonGrid ${
            buttons?.length > 1 ? 'changeOrderOnMd' : ''
          }`}>
          {buttons?.map((button) => (
            <Button
              component={button.path ? NavigatorLink : 'button'}
              href={button.path}
              key={button.text}
              variant="outlined"
              color="secondary"
              className={`button ${button.classes} link`}
              onClick={button.onClick}>
              {button.text}
            </Button>
          ))}
        </Box>
      )}
    </StyledBox>
  );
};

export { SliderSectionHeader };
