import React from 'react';
import { SliderDots } from 'views/common/components';
import StyledSliderSectionHeader, {
  StyledCarousel,
} from 'views/common/components/UI/SliderSectionContiner/StyledCarousel.styles';
import { useSelector } from 'react-redux';

const responsive = (
  largedesktopItems,
  deskTopItems,
  tabletItem,
  mobileItem,
  mobilePartialVisibilityGutter,
  desktopPartialVisibilityGutter,
  largeDesktopPartialVisibilityGutter,
  tabletPartialVisibilityGutter,
) => ({
  largedesktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: largedesktopItems,
    partialVisibilityGutter: largeDesktopPartialVisibilityGutter, // this is needed to tell the amount of px that should be visible.
  },
  desktop: {
    breakpoint: { max: 1200, min: 1024 },
    items: deskTopItems,
    slidesToSlide: deskTopItems,
    partialVisibilityGutter: desktopPartialVisibilityGutter, // this is needed to tell the amount of px that should be visible.
  },
  tablet: {
    breakpoint: { max: 1023, min: 600 },
    items: tabletItem,
    slidesToSlide: 1,
    partialVisibilityGutter: tabletPartialVisibilityGutter,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: mobileItem,
    slidesToSlide: 1,
    partialVisibilityGutter: mobilePartialVisibilityGutter,
  },
});
const SliderSectionContiner = (props) => {
  const {
    title,
    description,
    buttons,
    children,
    deskTopItems,
    carouselArrow,
    className,
    component,
    descComponent,
    mobilePartialVisibilityGutter = 30,
    tabletPartialVisibilityGutter = 20,
    tabletItem = 2,
    mobileItem = 1,
    desktopPartialVisibilityGutter = 0,
    largeDesktopPartialVisibilityGutter = 0,
    largedesktopItems = deskTopItems,
  } = props;
  const { isSSR, isCrawler, deviceType } = useSelector(
    (state) => state.globalData,
  );

  return (
    <StyledSliderSectionHeader
      title={title}
      component={component}
      description={description}
      buttons={buttons}
      className={className}
      descComponent={descComponent}>
      <StyledCarousel
        partialVisible={children.length != 1}
        swipeable
        draggable={false}
        ssr={isSSR}
        deviceType={deviceType}
        keyBoardControl
        arrows={!isCrawler && carouselArrow}
        autoPlay={false}
        renderButtonGroupOutside
        shouldResetAutoplay={false}
        containerClass="carousel-container"
        responsive={responsive(
          largedesktopItems,
          deskTopItems,
          tabletItem,
          mobileItem,
          mobilePartialVisibilityGutter,
          desktopPartialVisibilityGutter,
          largeDesktopPartialVisibilityGutter,
          tabletPartialVisibilityGutter,
        )}
        customTransition="transform 200ms ease-in"
        customButtonGroup={
          children.length > 1 && (
            <SliderDots slidesArray={children} length={children.length} />
          )
        }>
        {children}
      </StyledCarousel>
    </StyledSliderSectionHeader>
  );
};
export { SliderSectionContiner };
