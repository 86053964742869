import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledBox = styled(Box)(({ theme, centerItems }) => ({
  '&.SDContiner': {
    width: theme.spacing(16),
    margin: 'auto',
    position: 'relative',
    '& .react-multi-carousel-list': {
      justifyContent: centerItems && 'center',
    },
  },
  '& .SDSlideItem': {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    backgroundColor: theme.palette.grey[50],
    borderRadius: '50%',
    margin: theme.spacing(2, 0),
    border: `2px solid ${theme.palette.grey[300]}`,
    '&.active': {
      backgroundColor: theme.palette.secondary.dark,
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
    },
  },
}));

export default StyledBox;
