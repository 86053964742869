/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import StyledBox from 'views/common/components/UI/SliderDots/SliderDots.styles';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Box } from '@mui/material';
import { SliderDostsArrows } from 'views/common/components/UI/SliderDots';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 5,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 5,
  },
};

const SliderDots = (props) => {
  const {
    slidesArray,
    responsiveStyle,
    getItemCurrentSlide = () => {},
    goToSlide,
    ...rest
  } = props;
  const {
    carouselState: { currentSlide },
  } = rest;
  const getSliderAdditionalTransfromValue = () => {
    let additionalTransfromValue;
    switch (currentSlide) {
      case 0:
        additionalTransfromValue = 1 * 5;
        break;
      case 1:
        additionalTransfromValue = 6 * 5;
        break;
      default:
        additionalTransfromValue = 11 * 5;
    }
    return additionalTransfromValue;
  };
  const centerItems = slidesArray.length < 5;
  useEffect(() => {
    getItemCurrentSlide(currentSlide + 1);
  }, [currentSlide]);
  return (
    <>
      <StyledBox className="SDContiner" centerItems={centerItems}>
        <Carousel
          arrows={false}
          additionalTransfrom={
            !centerItems && getSliderAdditionalTransfromValue()
          }
          renderButtonGroupOutside
          responsive={responsiveStyle || responsive}
          swipeable
          draggable
          customTransition="all 0.4s"
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          autoPlay={false}
          shouldResetAutoplay={false}
          customButtonGroup={
            slidesArray.length > 4 && (
              <SliderDostsArrows
                goToImage={goToSlide}
                currentImage={currentSlide}
                slidesArraySize={slidesArray.length}
              />
            )
          }>
          {slidesArray.map((item, index) => (
            <Box
              className={
                currentSlide === index ? 'SDSlideItem active' : 'SDSlideItem'
              }
              onClick={() => {
                goToSlide(index);
              }}
              key={index}
            />
          ))}
        </Carousel>
      </StyledBox>
    </>
  );
};
export { SliderDots };
