/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import StyledBox from 'views/common/components/UI/SliderDots/SliderDostsArrows/SliderDostsArrows.styles';
import { Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const SliderDostsArrows = (props) => {
  const {
    goToSlide, currentSlide, goToImage, currentImage, slidesArraySize,
  } = props;
  useEffect(() => {
    if (currentImage !== currentSlide) {
      goToSlide(currentImage);
    }
  }, [currentImage]);

  const handlePreviousBtnClick = () => {
    if (currentImage <= 0) {
      goToImage(slidesArraySize - 1);
      goToSlide(slidesArraySize - 1);
    } else {
      goToImage(currentImage - 1);
      goToSlide(currentImage - 1);
    }
  };
  const handleNextBtnClick = () => {
    if (currentImage < slidesArraySize - 1) {
      goToImage(currentImage + 1);
      goToSlide(currentImage + 1);
    } else {
      goToImage(0);
      goToSlide(0);
    }
  };
  return (
    <StyledBox>
      <Button
        onClick={handlePreviousBtnClick}
        className="SDAArrow SDALeftArrow">
        <ChevronLeftIcon />
      </Button>

      <Button onClick={handleNextBtnClick} className="SDAArrow SDARightArrow">
        <ChevronRightIcon />
      </Button>
    </StyledBox>
  );
};

export { SliderDostsArrows };
